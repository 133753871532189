.ember-light-table-wrapper {
  width: 100%;
  overflow-x: scroll;
}

.ember-light-table {
  .lt-head table {
    margin-bottom: 0;
  }

  .lt-body-wrap {
    overflow-y: auto;
  }

  .lt-cell {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .total-cell {
    text-align: right;
  }
}

.ace-datetimepicker {
  display: block;
}

.tags-field input {
  padding-left: 10px !important
}

.ember-power-select-multiple-trigger {
  height: auto !important;
  max-height: 100px;
}

.input-group .input-group-btn {
  padding: 0;
  border: unset;
}

.input-group .input-group-text {
  height: 100%;
}
